<template>
  <div class="the-navbar__user-meta flex items-center" v-if="userInfo.name">

    <div class="text-right leading-tight hidden sm:block">
      <p class="font-semibold">{{ userInfo.name }}</p>
      <small>{{ userInfo.email }}</small>
    </div>

    <vs-dropdown vs-custom-content vs-trigger-click class="cursor-pointer">

      <div class="con-img ml-3">
        <img v-if="userInfo.picture" key="onlineImg" :src="userInfo.picture" alt="user-img" width="40" height="40" class="rounded-full shadow-md cursor-pointer block" />
      </div>

      <vs-dropdown-menu class="vx-navbar-dropdown">
        <ul style="min-width: 9rem">

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="$router.push(`/${$route.params.lang}/${userRolesDic[$store.state.AppActiveUser.userRole]}/account-settings`).catch(() => {})">
            <feather-icon icon="UserIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t('Account Settings')}}</span>
          </li>

          <li v-if="releaseVersion !== 'staging'"
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="openBetaVersion()">
            <feather-icon icon="RepeatIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{releaseVersion.includes('beta') ? $t('Stable Release') : $t('Try Beta')}}</span>
          </li>

          <vs-divider class="m-1" />

          <li
            class="flex py-2 px-4 cursor-pointer hover:bg-primary hover:text-white"
            @click="logout">
            <feather-icon icon="LogOutIcon" svgClasses="w-4 h-4" />
            <span class="ml-2">{{$t('Logout')}}</span>
          </li>
        </ul>
      </vs-dropdown-menu>
    </vs-dropdown>
  </div>
</template>

<script>
export default {
  data () {
    return {
      lang: 'ar',
      releaseVersion: process.env.VUE_APP_RELEASE_VERSION,
      userRolesDic: {
        'fc-operations-supervisor': 'fc-operations-supervisor',
        'fc-receiving-agent':'fc-receiving-agent',
        'fc-inbounding-agent':'fc-inbounding-agent',
        'fc-storing-agent': 'fc-storing-agent',
        'fc-packing-agent': 'fc-packing-agent',
        'fc-picking-agent': 'fc-picking-agent'
      },
      userInfo: {}
    }
  },
  methods: {
    openBetaVersion () {
      if (this.releaseVersion.includes('beta')) {
        window.open('https://app.shipblu.com/', '_blank')
      } else {
        window.open('https://app.beta.shipblu.com/', '_blank')
      }
    },
    logout () {
      this.$auth.logOut()

      // Change role on logout. Same value as initialRole of acj.js
      this.$acl.change('guest')
      localStorage.removeItem('multipleWarehouses')
      localStorage.removeItem('idTokenPayload')

      // This is just for demo Purpose. If user clicks on logout -> redirect
      // this.$router.push('/pages/login')
    }
  },
  created () {
    this.lang = this.$route.params.lang
    this.userInfo = localStorage.getItem('idTokenPayload') ? JSON.parse(localStorage.getItem('idTokenPayload')) : {}
  }
}
</script>
